import React, { useEffect, useRef } from "react"
import Navigation from "../../layout/navigation/Navigation"
import { useHasBeenViewed } from "../../hooks/useHasBeenViewed"
import smoothscroll from "smoothscroll-polyfill"
import { Link } from "react-router-dom"

import "./landing.sass"
import "../../assets/sass/_animations.sass"

import clsx from "clsx"
import { ArrowRight } from "react-feather"

smoothscroll.polyfill()

export default function Landing() {
  useEffect(() => {
    document.title = "Kandidly | Your Gateway to Top Tech Talent"
  }, [])

  const howItWorksSection = useRef(null)

  const [isStep1Visible, step1] = useHasBeenViewed({ threshold: 35 })
  const [isStep2Visible, step2] = useHasBeenViewed({ threshold: 35 })
  const [isStep3Visible, step3] = useHasBeenViewed({ threshold: 35 })
  const [isStep4Visible, step4] = useHasBeenViewed({ threshold: 35 })

  return (
    <main>
      <Navigation activePage="home" collapse />
      <section
        className="section is-hero hero landing-hero is-fullheight has-custom-font"
        role="contentinfo"
        aria-labelledby="sectionHeading"
        name="Landing Hero"
      >
        {/* <div className="hero-bg fade-in-slow" /> */}
        <div className="hero-body center">
          <div className="container">
            <div className="columns">
              <div className="column is-flex is-flex-direction-column is-justify-content-center">
                <h1 className="title is-spaced pb-3 slide-in-bottom has-cascade-effect mb-0">
                Your Gateway to Top Tech Talent
                </h1>
                <h3
                  className=" slide-in-bottom has-cascade-effect has-text-grey landing-subtitle"
                  style={{ maxWidth: 420 }}
                >
                  Unlock the power of industry expertise. Get talent referrals from pros who've been there, done that.

                </h3>
                <div className="buttons mt-5 fade-in-fast slide-in-bottom has-cascade-effect">
                  <Link
                    to={{ pathname: "https://form.jotform.com/240585162765260"}}
                    target="_blank"
                    className="button is-size-5 is-primary is-rounded-large has-text-weight-semibold cta-button has-custom-font"
                  >
                    Get Started
                  </Link>

                  <button
                    className="button is-white is-rounded-large has-text-grey-dark has-text-weight-semibold has-custom-font is-size-6 is-text ml-3"
                    onClick={() => {
                      if (howItWorksSection.current) {
                        const y =
                          howItWorksSection.current.getBoundingClientRect()
                            .top + window.pageYOffset

                        window.scrollTo({ top: y, behavior: "smooth" })
                      }
                    }}
                  >
                    Learn more
                  </button>
                </div>
              </div>
              <div className="column is-three-fifths">
                <img
                  src={require("../../assets/images/landing/hero-img.png")}
                  alt=""
                  className="fade-in slide-in-bottom"
                  loading="lazy"
                  draggable={false}
                  width="auto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr className="container" />
      <section className="section pt-5 has-custom-font" ref={howItWorksSection}>
        <h2 className=" has-text-centered is-size-3 has-text-weight-semibold mb-6 mt-6">
        🎯 How It Works
        </h2>

        {/* PROFILE */}
        <div
          className={clsx(
            "columns container is-fluid is-7 is-variable is-centered mb-5",
            {
              "is-invisible": !isStep1Visible,
              "slide-in-bottom": isStep1Visible
            }
          )}
          ref={step1}
        >
          <div className="column is-7 has-text-centered">
            <img
              src={require("../../assets/images/landing/jobs.png")}
              alt="Your Jobs Dashboard"
              className="landing-product-img fade-in"
              loading="lazy"
              draggable={false}
            />
          </div>
          <div className="column is-flex is-justify-content-center is-flex-direction-column">
            <div className="landing-product-text">
              <h3 className="has-text-weight-semibold is-size-5 ">
                Post your Job
              </h3>
              <p className="mt-3">
              Tell us about your role and requirements. We'll ensure your job listing reaches the right eyes.
              </p>
            </div>
          </div>
        </div>

        {/* JOBS */}
        <div
          className={clsx(
            "columns is-vcentered container is-fluid is-7 is-variable is-centered pt-6 is-reversed mb-5",
            {
              "is-invisible": !isStep2Visible,
              "slide-in-bottom": isStep2Visible
            }
          )}
          ref={step2}
        >
          <div className="column is-flex is-justify-content-center is-flex-direction-column is-align-items-flex-end">
            <div className="landing-product-text">
              <h3 className="has-text-weight-semibold is-size-5">
              Explore Exceptional Profiles
              </h3>
              <p className="mt-3">
              Gain access to our exclusive network of tech industry professionals, where each candidate is a direct referral from trusted industry insiders. Say goodbye to sifting through endless resumes and hello to a curated selection of qualified individuals.
              </p>
            </div>
          </div>
          <div className="column is-7 has-text-centered">
            <img
              src={require("../../assets/images/landing/candidates.png")}
              alt="Your Candidates Dashboard"
              className="landing-product-img fade-in"
              loading="lazy"
              draggable={false}
            />
          </div>
        </div>

        {/* EXTENSION */}
        <div
          className={clsx(
            "columns container is-fluid is-7 is-variable is-centered pt-6 mb-5",
            {
              "is-invisible": !isStep3Visible,
              "slide-in-bottom": isStep3Visible
            }
          )}
          ref={step3}
        >
          <div className="column is-7 has-text-centered">
            <img
              src={require("../../assets/images/landing/candidate.png")}
              alt="Job Candidate Profile"
              className="landing-product-img fade-in"
              loading="lazy"
              draggable={false}
            />
          </div>
          <div className="column is-flex is-justify-content-center is-flex-direction-column">
            <div className="landing-product-text">
              <h3 className="has-text-weight-semibold is-size-5">
              Select Top Candidates
              </h3>
              <p className="mt-3">
              Effortlessly choose top candidates from our curated shortlist. Explore detailed profiles highlighting their experience, endorsements, and why they come highly recommended by industry insiders.
              </p>

            </div>
          </div>
        </div>

        {/* APPLICATIONS */}
        <div
          className={clsx(
            "columns is-vcentered container is-fluid is-7 is-variable is-centered pt-6 is-reversed mb-5",
            {
              "is-invisible": !isStep4Visible,
              "slide-in-bottom": isStep4Visible
            }
          )}
          ref={step4}
        >
          <div className="column is-flex is-justify-content-center is-flex-direction-column is-align-items-flex-end">
            <div className="landing-product-text">
              <h3 className="has-text-weight-semibold is-size-5 ">
              Discover Referrer Insights
              </h3>
              <p className="mt-3">
              Gain valuable insights into the professionals vouching for each candidate. Uncover their background and expertise, adding an extra layer of confidence to your hiring decisions.
              </p>
            </div>
          </div>
          <div className="column is-7 has-text-centered">
            <img
              src={require("../../assets/images/landing/referrer.png")}
              alt="Referrer Profile"
              className="landing-product-img fade-in"
              loading="lazy"
              draggable={false}
            />
          </div>
        </div>
      </section>

      <hr className="container mt-5" />
      <section className="section pt-5 has-custom-font has-text-centered" >
        <h2 className=" has-text-centered is-size-3 has-text-weight-semibold mb-6 mt-6">
        🌟 Why Kandidly
        </h2>
        <div
          className={clsx(
            "container is-fluid is-7 is-variable is-centered is-reversed mb-5"
          )}
        >
<div class="columns is-vcentered is-centered">
  <div class="column is-8">
  <div class="content columns is-vcentered ">
      <div class="column is-3">
        <p class="is-size-4">
          <strong class="has-text-weight-bold">Quality Over Quantity</strong>
        </p>
      </div>
      <div class="column has-text-left-tablet">
        <p class="is-size-5">
          Forget sifting through endless resumes. We bring you candidates backed by the trust and experience of industry experts.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="columns is-vcentered is-centered">
  <div class="column is-8">
  <div class="content columns is-vcentered ">
      <div class="column is-3">
        <p class="is-size-4">
          <strong class="has-text-weight-bold">Time-Efficient</strong>
        </p>
      </div>
      <div class="column has-text-left-tablet">
        <p class="is-size-5">
          Cut down on the hiring process. Our network accelerates the identification of top talent, ensuring your positions are filled promptly.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="columns is-vcentered is-centered">
  <div class="column is-8">
    <div class="content columns is-vcentered ">
      <div class="column is-3">
        <p class="is-size-4">
          <strong class="has-text-weight-bold">Built on Trust</strong>
        </p>
      </div>
      <div class="column has-text-left-tablet">
        <p class="is-size-5">

          We value the integrity of our network. Every referral is a testament to the skills and capabilities of the recommended candidate.
  
        </p>
      </div>
    </div>
  </div>
</div>




        </div>
      </section>
      <hr className="container mt-5" />

      <section className="section pt-5 has-custom-font has-text-centered">
        <div className="columns is-centered">
        <div className="column is-8">
        <h2 className="is-size-3 has-text-weight-semibold mb-6 mt-6">
        💸 Unlock Top Talent Without Breaking the Bank!
        </h2>
        <p className="is-size-5">Tired of hefty recruitment fees and the unpredictability of traditional agencies? Kandidly is here to revolutionize your hiring strategy and save you more than just dollars.</p>
        </div>
        </div>
        </section>

        <hr className="container mt-5" />

      <section className="section has-custom-font has-text-centered">
        <h2 className="is-size-5 has-text-weight-semibold mb-2">
        🤝 Join Kandidly and Elevate Your Hiring Game!
        </h2>
        <p className="mb-2 m-auto has-text-grey" style={{ maxWidth: 520 }}>
        Ready to let industry experts guide you to the best talent?
        </p>
        <Link
          to={{ pathname: "https://form.jotform.com/240585162765260"}}
          target="_blank"
          className="button is-text has-text-weight-semibold has-custom-font mr-0 has-text-primary"
        >
          Get Started
          <ArrowRight className="ml-2" strokeWidth={2} />
        </Link>
      </section>


      <footer className="has-text-grey pb-5 mt-6">
        <div className="container is-flex is-justify-content-space-between is-align-items-center">
          <img
            src={require("../../assets/images/logo-dark.png")}
            alt="Applyd Logo"
            width="110px"
            height="32px"
          />
          {/* <Link to="/privacy" className="has-text-dark has-text-">
            Privacy Policy
          </Link> */}
          {/*<p>© 2021</p>*/}
        </div>
      </footer>
    </main>
  )
}

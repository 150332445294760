import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom"
import { AuthContext } from "../context/Auth"
import Loading from "../layout/Loading"
import { routes } from "./routes"

const RedirectRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser, pending } = useContext(AuthContext)

  let redirectPage = rest.location?.state?.redirectPage
  const destination = localStorage.getItem("dest")

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        // li = user has logged in before
        pending || (localStorage.getItem("li") && !!!currentUser) ? (
          <Loading showDelayedLogout />
        ) : !!currentUser && currentUser.uid ? (
          <Redirect
            to={
              redirectPage
                ? routes[redirectPage]
                : destination === "ap"
                ? routes.applications
                : routes.profile
            }
          />
        ) : (
          <RouteComponent {...routeProps} {...rest} />
        )
      }
    />
  )
}

export default RedirectRoute

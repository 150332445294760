import { useState, useEffect } from "react"
import useIsInViewport from "use-is-in-viewport"

export const useHasBeenViewed = (options) => {
  const [isInViewport, ref, viewportRef] = useIsInViewport(options)
  const [hasBeenInViewportOnce, setHasBeenViewed] = useState(false)

  useEffect(() => {
    if (isInViewport && !hasBeenInViewportOnce) {
      setHasBeenViewed(true)
    }
  }, [isInViewport, options, hasBeenInViewportOnce])

  return [hasBeenInViewportOnce, ref, viewportRef]
}

import React, { useEffect } from "react"
import Navigation from "../../layout/navigation/Navigation"
import smoothscroll from "smoothscroll-polyfill"

import "./landing.sass"
import "../../assets/sass/_animations.sass"

smoothscroll.polyfill()

export default function ThankYou() {
  useEffect(() => {
    document.title = "Kandidly | Your Gateway to Top Tech Talent"
  }, [])


  return (
    <main>
      <Navigation activePage="home" collapse />
      <section
        className="section is-hero hero landing-hero is-fullheight has-custom-font"
        role="contentinfo"
        aria-labelledby="sectionHeading"
        name="Landing Hero"
      >
        {/* <div className="hero-bg fade-in-slow" /> */}
        <div className="hero-body center">
          <div className="container">
            <div className="columns">
              <div className="column is-flex is-flex-direction-column is-justify-content-center">
                <h1 className="title has-text-centered  is-spaced pb-3 slide-in-bottom has-cascade-effect mb-0">
                Thank you for your interest, we'll be in touch over email!
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>



      <footer className="has-text-grey pb-5 mt-6">
        <div className="container is-flex is-justify-content-space-between is-align-items-center">
          <img
            src={require("../../assets/images/logo-dark.png")}
            alt="Kandidly Logo"
            width="110px"
            height="32px"
          />
          {/* <Link to="/privacy" className="has-text-dark has-text-">
            Privacy Policy
          </Link> */}
          {/*<p>© 2021</p>*/}
        </div>
      </footer>
    </main>
  )
}
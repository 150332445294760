import React, { useEffect, useState } from "react"
import { routes } from "../routers/routes"

export default function Loading({ showDelayedLogout, loadingText }) {
  const [showRetry, setShowRetry] = useState(false)

  useEffect(() => {
    let loadingTimer = setTimeout(() => {
      setShowRetry(true)
    }, 10000)
    return () => clearTimeout(loadingTimer)
  }, [])

  const logoutAndRedirect = () => {
    require("../firebase").auth.signOut()
    localStorage.removeItem("li")
    window.location.replace(routes.home)
  }

  return (
    <main className="hero container is-fullheight is-fluid has-background-white is-flex is-align-items-center is-justify-content-center fade-in">
      <div className="loading-container is-flex is-align-items-center is-justify-content-center pulse">
        <img src={require("../assets/images/lettermark.png")} alt="Applyd" />
      </div>
      {showDelayedLogout && showRetry ? (
        <h1 className="has-text-weight-semibold mt-6 is-size-6 has-custom-font has-text-grey fade-in">
          This is taking longer than expected.{" "}
          <span
            className="is-clickable has-text-primary"
            onClick={logoutAndRedirect}
          >
            Log out and retry
          </span>
          .
        </h1>
      ) : (
        loadingText && (
          <h1 className="has-text-weight-semibold mt-6 is-size-5 has-custom-font has-text-grey">
            {loadingText}
          </h1>
        )
      )}
    </main>
  )
}

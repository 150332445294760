import React from "react"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import ReactNotification from "react-notifications-component"
import "react-notifications-component/dist/theme.css"

import { routes } from "./routers/routes"
import RedirectRoute from "./routers/RedirectRoute"
import { AuthProvider } from "./context/Auth"

import Landing from "./views/landing/Landing"
import ThankYou from "./views/landing/ThankYou"

function App() {
  return (
    <AuthProvider>
      <ReactNotification />
      <Router>
        <Switch>
          <RedirectRoute exact path={routes.home} component={Landing} />
          <RedirectRoute exact path={routes.thankYou} component={ThankYou} />
          {/* <RedirectRoute exact path={routes.login} component={Login} />
          <RedirectRoute exact path={routes.signup} component={Signup} />
          <RedirectRoute
            exact
            path={routes.forgotPassword}
            component={ForgotPassword}
          /> */}
          {/* <PrivateRoute
            exact
            path={routes.dashboard}
            component={Dashboard}
            page="dashboard"
          /> */}
          {/* <PrivateRoute
            exact
            path={routes.profile}
            component={Dashboard}
            page="profile"
          />
          <PrivateRoute
            exact
            path={routes.applications}
            component={Dashboard}
            page="applications"
          />
          <PrivateRoute
            exact
            path={routes.jobs}
            component={Dashboard}
            page="jobs"
            pageProps={{ saved: false }}
          />
          <PrivateRoute
            exact
            path={routes.savedJobs}
            component={Dashboard}
            page="jobs"
            pageProps={{ saved: true }}
          />
          <PrivateRoute
            exact
            path={routes.feedback}
            component={Dashboard}
            page="feedback"
          /> */}
          {/* <PrivateRoute
            exact
            path={routes.emails}
            component={Dashboard}
            page="emails"
          />
          */}
          {/* <PrivateRoute
            exact
            path={routes.settings}
            component={Dashboard}
            page="settings"
          />
          <Route exact path={routes.contact} component={Contact} />
          <Route exact path={routes.privacyPolicy} component={Privacy} />
          <Route path="*" status={404} /> */}
        </Switch>
      </Router>
    </AuthProvider>
  )
}

export default App

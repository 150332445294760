import React from "react"
import ReactDOM from "react-dom"
import "./assets/sass/index.sass"
import App from "./App"


ReactDOM.render(
  //<React.StrictMode>
  <App />,
  //</React.StrictMode>
  document.getElementById("root")
)

import React, { useRef, useState, useEffect, useCallback } from "react"
import { app, auth } from "../firebase"
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import axios from "axios"

// import { notify } from "../components/notification/Notification"

export const AuthContext = React.createContext()

window.browser = (function () {
  return window.chrome
})()

export const AuthProvider = ({ children }) => {
  let [currentUser, setCurrentUser] = useState(null)
  let [pending, setPending] = useState(false)
  let hasBrowserExtension = useRef(null)

  const getToken = useCallback(async (user) => {
    if (user) {
      let result = await user.getIdTokenResult()

      if (result.claims["https://hasura.io/jwt/claims"]) {
        // console.log("inside if claims")
        return result.token
      } else {
        // console.log("after if before refresh call")
        let newTokenRes = await axios.get(
          `${process.env.REACT_APP_REFRESH_FUNCTION_URL}?uid=${auth.currentUser.uid}`
        )
        if (newTokenRes?.status === 200) {
          let newToken = await auth.currentUser.getIdToken(true)
          return newToken
        } else {
          return null
          // throw new Error("custom claims not set for user")
        }
      }
    }
  }, [])

  useEffect(() => {
    // console.log("USE EFFECT")

    // send message to our chrome extension and check for reply to toggle hasBrowserExtension flag
    if (window?.chrome?.runtime?.sendMessage) {
      // console.log("check exension")
      window.chrome.runtime.sendMessage(
        process.env.REACT_APP_CHROME_EXTENSION_ID,
        { message: "version" },
        function (reply) {
          if (window.chrome.runtime.lastError) {
            hasBrowserExtension.current = false
            return
          }
          // console.log("REPLY", reply)
          if (reply && reply.version) {
            hasBrowserExtension.current = true
          } else {
            hasBrowserExtension.current = false
          }
        }
      )
    }

    // setPending(true)
    // let token
    // app.auth().onIdTokenChanged((firebaseUser) => {
    //   console.log("ID TOKEN CHANGED", firebaseUser)
    //   //   //console.log(
    //   //     //  "last sign in,",
    //   //     //  app.auth().currentUser.metadata.lastSignInTime
    //   //     //)
    //   if (firebaseUser) {
    //     console.log("in onIdToken if")

    //   }
    // })

    app.auth().onAuthStateChanged(async (firebaseUser) => {
      // console.log("auth state listener", firebaseUser)
      if (firebaseUser?.uid) {
        setPending(true)
        localStorage.setItem("li", "true")
        // console.log("in auth then validtoken")
        // setAccessToken(token)
        setCurrentUser(firebaseUser)

        // if user has browser extension installed, send the custom token to it (only if they aren't logged in to the extrnsion already)
        if (
          hasBrowserExtension.current &&
          window?.chrome?.runtime?.sendMessage
        ) {
          // console.log("HAS EXTENSION")
          window.chrome.runtime.sendMessage(
            process.env.REACT_APP_CHROME_EXTENSION_ID,
            { message: "auth-check" },
            async (response) => {
              const result = await response
              // console.log("ext resp", result)
              if (
                !result?.localStorageEntry ||
                result?.email !== firebaseUser.email
              ) {
                const tokenRes = await axios.post(
                  process.env.REACT_APP_CUSTOM_TOKEN_FUNCTION_URL,
                  {
                    uid: firebaseUser.uid
                  }
                )

                if (tokenRes?.status === 200 && tokenRes.data) {
                  //console.log("TOKEN REQUEST", tokenRes)
                  window.chrome.runtime.sendMessage(
                    process.env.REACT_APP_CHROME_EXTENSION_ID,
                    {
                      message: "token",
                      token: tokenRes.data
                    },
                    async (response) => {
                      let result = await response
                      //console.log(result.localStorageEntry)
                      if (result?.status === 200) {
                        // console.log(result)
                        // notify({ title: result.message })
                      }
                    }
                  )
                }
              }
            }
          )
        }

        // if new user just signed up, get token with custom claims first, before showing dashboard
        if (
          firebaseUser.metadata.creationTime ===
          firebaseUser.metadata.lastSignInTime
        ) {
          // console.log("new user")
          await getToken(firebaseUser)
          setPending(false)
        } else {
          // console.log("old user")
          setPending(false)
        }
      } else {
        // setPending(false)
        // console.log("auth else")
        // if logged in but something went wrong with auth, log them out manually (useful when their token is expired after they changed password)
        if (localStorage.getItem("li") && !localStorage.getItem("logout")) {
          localStorage.removeItem("li")
          auth.signOut()
          window.location.href = "/login"
        } else {
          localStorage.removeItem("logout")
        }

        // log out (or not logged in at all)
        setCurrentUser(null)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_ENDPOINT
  })

  const authLink = setContext(async (_, { headers }) => {
    // console.log("in auth link with token")
    let token = await getToken(currentUser)
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    }
  })

  const createApolloClient = () => {
    return new ApolloClient({
      connectToDevTools: true,
      link: authLink.concat(httpLink),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              job_applications: {
                // if the _or in the where argument changes (is different from cached query definition), cache this as separate query
                // This overrites the existing data and allows us to update ui with only this query's results
                // When anything else changes, like created_at (the cursor for pagination), we do not want to cache this elsewhere
                // or overwrite existing query results, instead keep existing the same and join with incoming.
                keyArgs: ["where", ["_or"]],
                // Concatenate the incoming list items with
                // the existing list items.
                merge(existing = [], incoming) {
                  return [...existing, ...incoming]
                }
              },
              job_applications_aggregate: {
                keyArgs: ["where", ["_or"]]
              },
              search_jobs: {
                keyArgs: ["args", ["search"], "where"],
                merge(existing = [], incoming, { args }) {
                  // if the incoming data is the first page, overwrite existing
                  if (args.offset === 0) return incoming
                  return [...existing, ...incoming]
                }
              }
            }
          }
        }
      })
    })
  }

  const client = createApolloClient()
  return (
    <AuthContext.Provider
      value={{
        currentUser,
        pending,
        hasBrowserExtension: hasBrowserExtension.current
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </AuthContext.Provider>
  )
}
